/* eslint-disable @next/next/next-script-for-ga */
import type { AppProps } from "next/app";
import { League_Spartan } from "next/font/google";
import Head from "next/head";

import { QueryClientProvider } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";

import { ChakraProvider } from "@chakra-ui/react";
import { AppProvider } from "~/contexts";
import { api } from "~/services/apiClient";
import { queryClient } from "~/services/queryClient";
import { theme } from "~/styles/theme";

import { ErrorBoundary } from "~/components/global/ErrorBoundary";
import { LivePopup } from "~/components/global/LivePopup";
import '~/styles/blockquote.css';
import * as gtag from "~/utils/lib/gtag";
import BaseLayoutAdmin from "./_layouts/admin";
import { GA_TRACKING_ID } from "~/utils/lib/gtag";

const fontLeagueSpartan = League_Spartan({
	subsets: ["latin"],
	weight: ["400", "500", "600", "700"],
	display: "swap",
});

function MyApp({ Component, pageProps, router }: AppProps) {
	const liveIsActive = useMemo(() => {
		switch (router.asPath.split("/")[1]) {
			case "admin":
			case "login":
			case "signin":
			case "signup":
			case "carousel":
			case "recovery":
			case "recovery-password":
			case "reset":
			case "reset-password":
			case "session":
				return false;

			default:
				return true;
		}
	}, []);

	const live = liveIsActive ? pageProps.data?.youtubeLive : {}
	const [youtubeLive, setYoutubeLive] = useState<any>(
		live
	);

	useEffect(() => {
		async function getLive() {
			const response = await api.get("/v1/live");

			setYoutubeLive(response.data);
		}
		if (!youtubeLive) getLive();

		if (router.asPath.split("/")[1] !== "admin") {
			gtag.event({
				action: "Navigate",
				category: "View",
				label: `/${router.pathname.split("/")[1]}`,
			});
		}
	}, []);

	return (
		<>
			<Head>
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				{process.env.NEXT_PUBLIC_APP_ENV === 'homolog' && (
					<meta name="robots" content="noindex, nofollow"></meta>
				)}
				{router.asPath.split("/")[1] === "admin" && (
					<meta name="robots" content="noindex" />
				)}

				<link rel="shortcut icon" href={pageProps.data?.settings?.favicon?.url ?? "/SA-32.png"} type="image/png" />

				<link rel="icon" sizes="512x512" href="/SA-512.png" type="image/png" />
				<link rel="icon" sizes="384x384" href="/SA-384.png" type="image/png" />
				<link rel="icon" sizes="256x256" href="/SA-256.png" type="image/png" />
				<link rel="icon" sizes="192x192" href="/SA-192.png" type="image/png" />
				<link rel="icon" sizes="180x180" href="/SA-180.png" type="image/png" />
				<link rel="icon" sizes="152x152" href="/SA-152.png" type="image/png" />
				<link rel="icon" sizes="120x120" href="/SA-120.png" type="image/png" />
				<link rel="icon" sizes="76x76" href="/SA-76.png" type="image/png" />

				<link rel="apple-touch-icon" sizes="512x512" href="/SA-512.png" type="image/png" />
				<link rel="apple-touch-icon" sizes="384x384" href="/SA-384.png" type="image/png" />
				<link rel="apple-touch-icon" sizes="256x256" href="/SA-256.png" type="image/png" />
				<link rel="apple-touch-icon" sizes="192x192" href="/SA-192.png" type="image/png" />
				<link rel="apple-touch-icon" sizes="180x180" href="/SA-180.png" type="image/png" />
				<link rel="apple-touch-icon" sizes="152x152" href="/SA-152.png" type="image/png" />
				<link rel="apple-touch-icon" sizes="120x120" href="/SA-120.png" type="image/png" />
				<link rel="apple-touch-icon" sizes="76x76" href="/SA-76.png" type="image/png" />

				<link rel="manifest" href="/manifest.json" />
				<link rel="preconnect" href="https://nyc3.digitaloceanspaces.com" />
				<link rel="preconnect" href="https://cdn.onesignal.com" />
				<link rel="preconnect" href="https://cdn.samaisvarejo.com.br" />
				<meta property="og:locale" content="pt_BR" />
				<script async src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"></script>
				{router.asPath.split("/")[1] !== "admin" && (
					<>
						<script>
							{`var googletag = googletag || {};
								googletag.cmd = googletag.cmd || [];`
							}
						</script>
						<script
							async
							src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
						/>
						<script
							dangerouslySetInnerHTML={{
								__html: ` !function (w, d, s, l, i) {
              w[l] = w[l] || []; w[l].push({
                'gtm.start':
                  new Date().getTime(), event: 'gtm.js'
              }); var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                  'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
            } (window, document, 'script', 'dataLayer', '${gtag.GTM_TRACKING_ID}');`,
							}}
						/>
						{(router.asPath.split("/")[1] !== 'login' && router.asPath.split("/")[1] !== 'signin') && (
							<script
								async
								type="text/javascript"
								src="https://d335luupugsy2.cloudfront.net/js/rdstation-forms/stable/rdstation-forms.min.js"
							></script>
						)}
					</>
				)}
				<title>{router.asPath.split("/")[1] === "admin" ? "CMS - SA+" : "SA+ Ecossistema de Varejo"}</title>
			</Head>
			<QueryClientProvider client={queryClient}>
				<ChakraProvider theme={theme}>
					<ErrorBoundary>
						<AppProvider settings={pageProps.data?.settings ?? pageProps.dataHome?.settings} >
							{router.asPath.split("/")[1] === "admin" ? (
								<BaseLayoutAdmin>
									<Component {...pageProps} />
								</BaseLayoutAdmin>
							) : (
								<main className={fontLeagueSpartan.className}>
									<Component {...pageProps} />
									{!!(liveIsActive &&
										!!youtubeLive &&
										youtubeLive?.items.length &&
										youtubeLive?.items[0].snippet.liveBroadcastContent !==
										"none") && <LivePopup items={youtubeLive.items} />}
									<script
										dangerouslySetInnerHTML={{
											__html: `
											window.dataLayer = window.dataLayer || [];
											function gtag(){dataLayer.push(arguments);}
											gtag('js', new Date());
											gtag('config', '${GA_TRACKING_ID}', {
													page_path: window.location.pathname,
												});
											`,
										}}
									/>
									<noscript>
										<iframe
											title="tagmanager"
											src="https://www.googletagmanager.com/ns.html?id=GTM-MB79QHG"
											height="0"
											width="0"
											style={{ display: "none", visibility: "hidden" }}
										></iframe>
									</noscript>
								</main>
							)}
						</AppProvider>
					</ErrorBoundary>
				</ChakraProvider>
				{/* <ReactQueryDevtools /> */}
			</QueryClientProvider>
		</>
	);
}

// Validação para remover barras duplas // em azure*
MyApp.getInitialProps = async ({ ctx }: any) => {
	const { req, res } = ctx;

	// Executa apenas no lado do servidor
	if (req) {
		const url = req.url;

		// Verifica se a URL contém duas barras consecutivas (ignorando o protocolo)
		if (url && url.includes('//')) {
			// Remove as barras duplas da URL
			const fixedUrl = url.replace(/\/{2,}/g, '/');

			// Redireciona para a URL corrigida
			res.writeHead(301, { Location: fixedUrl });
			res.end();
		}
	}

	// Retorna as props da página normalmente
	return { pageProps: {} };
};

export default MyApp;
